import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InternalLocalFileSofEntitiesVisibility as Selection } from '@xbs/xbs-enums';
import { ConnectorProps } from './ConfigurationTab.proptype';
import { createIncludeAllEntitiesConfig } from './utils/createIncludeAllEntitiesConfig';
import { createIncludeAllTransactionsConfig } from './utils/createIncludeAllTransactionConfig';
import { useCustomizedControlledTransactions } from '../../../hooks/configuration-tab/useCustomizedControlledTransactions';
import { useCustomizedEntityStatementOfFacts } from '../../../hooks/configuration-tab/useCustomizedEntityStatementOfFacts';
import { useCustomizedReportSection } from '../../../hooks/configuration-tab/useCustomizedReportSection';
import { useAppendixConfigState } from '../../../hooks/localfile-report/useAppendixConfigState';
import { useSelectedProfitBasedAnalysisConfig, useSelectPBAsListBySelectedTransactions } from '../../../hooks/pbas';
import { useSelectedPrimaryEntities } from '../../../hooks/primary-entities/useSelectedPrimaryEntities';
import { useSelectedEntityStatementsOfFacts } from '../../../hooks/report/useSelectedEntityStatementsOfFacts';
import { useSelectedTradingPartners } from '../../../hooks/trading-partners/useSelectedTradingPartners';
import { useSelectedControlledTransactionsDocumentationConfig } from '../../../hooks/transaction/useSelectedControlledTransactionsDocumentationConfig';
import { useSelectedTransactions } from '../../../hooks/transaction/useSelectedTransactions';
import { useUPE } from '../../../hooks/upe/useUPE';
import { CustomAppendix } from '../../../models/reports.interface';
import { actions } from '../../../redux/localReports';
import { ProfitBasedAnalysisConfig } from '../../../redux/localReports/localReports.proptype';

const INITIAL_STATE_SELECTED = 0;

const Connector = ({ component: Component, selectedSection, emitSection, jurisdictionName }: ConnectorProps) => {
  const dispatch = useDispatch();

  const selectedTransactions = useSelectedTransactions();
  const selectedAppendixConfigState = useAppendixConfigState();
  const customizedLegalEntities = useCustomizedEntityStatementOfFacts();
  const customizedControlledTransactions = useCustomizedControlledTransactions();
  const customizedReportSections = useCustomizedReportSection();

  const upe = useUPE();
  const selectedPrimaryEntities = useSelectedPrimaryEntities();
  const selectedTradingPartners = useSelectedTradingPartners();

  const pbaList = useSelectPBAsListBySelectedTransactions();

  const selectedLegalEntityConfig = useSelectedEntityStatementsOfFacts();
  const selectedControlledTransactionsDocumentationConfig = useSelectedControlledTransactionsDocumentationConfig();
  const selectedProfitBasedAnalysisConfig = useSelectedProfitBasedAnalysisConfig();

  const previousPbaConfig = useSelectedProfitBasedAnalysisConfig();
  const previousReportPbaConfig = previousPbaConfig.pbaHideFinancials;

  // Appendix Section --------------
  const dispatchCurrentAppendixInAppendixConfigState = (appendixId: CustomAppendix['identifier']) => {
    dispatch(actions.setCurrentAppendixInAppendixConfigState(appendixId));
  };

  const dispatchAddNewAppendixInAppendixConfigState = (newAppendix: CustomAppendix) => {
    dispatch(actions.addCustomAppendix(newAppendix));
  };
  // Appendix Section --------------

  const emitSectionSelected = (n: number) => emitSection(n);

  const filterCustomPrimaryEntitiesSelection = useCallback(
    (entitiesIds: number[]) => {
      return entitiesIds.filter((e) => Boolean(selectedPrimaryEntities.some((ent) => ent.entityId === e)));
    },
    [selectedPrimaryEntities]
  );

  const filterCustomTradingPartnersSelection = useCallback(
    (entitiesIds: number[]) => {
      return entitiesIds.filter((e) => Boolean(selectedTradingPartners.some((ent) => ent.entityId === e)));
    },
    [selectedTradingPartners]
  );

  const filterCustomTransactionSelection = useCallback(
    (transactionsIds: number[]) => {
      return transactionsIds.filter((t) => Boolean(selectedTransactions.some((tran) => tran.transactionId === t)));
    },
    [selectedTransactions]
  );

  useEffect(() => {
    if (
      selectedLegalEntityConfig?.optionSelected === Selection.ByName.IncludeAll.Id ||
      selectedLegalEntityConfig?.optionSelected === INITIAL_STATE_SELECTED
    ) {
      const partialEntitiesConfig = createIncludeAllEntitiesConfig(
        selectedPrimaryEntities,
        selectedTradingPartners,
        upe!
      );

      const localFileState = {
        ...partialEntitiesConfig,
        displayOrgChart: selectedLegalEntityConfig.displayOrgChart
      };

      dispatch(actions.setConfigurationTabEntityStatementOfFactsConfig(localFileState));
    } else if (selectedLegalEntityConfig?.optionSelected === Selection.ByName.Custom.Id) {
      const localFileState = {
        ...selectedLegalEntityConfig,
        entitiesSelected: {
          primaryEntityIds: filterCustomPrimaryEntitiesSelection(
            selectedLegalEntityConfig.entitiesSelected.primaryEntityIds
          ),
          tradingPartnersIds: filterCustomTradingPartnersSelection(
            selectedLegalEntityConfig.entitiesSelected.tradingPartnersIds
          )
        }
      };
      dispatch(actions.setConfigurationTabEntityStatementOfFactsConfig(localFileState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedControlledTransactionsDocumentationConfig.optionSelected === Selection.ByName.IncludeAll.Id ||
      selectedControlledTransactionsDocumentationConfig.optionSelected === INITIAL_STATE_SELECTED
    ) {
      const transactionConfig = createIncludeAllTransactionsConfig(selectedTransactions);
      dispatch(actions.setControlledTransactionsDocumentationConfig(transactionConfig));
    } else if (selectedControlledTransactionsDocumentationConfig.optionSelected === Selection.ByName.Custom.Id) {
      const transactionConfig = {
        ...selectedControlledTransactionsDocumentationConfig,
        transactionsSelectedIds: filterCustomTransactionSelection(
          selectedControlledTransactionsDocumentationConfig.transactionsSelectedIds
        )
      };
      dispatch(actions.setControlledTransactionsDocumentationConfig(transactionConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pbasConfig: ProfitBasedAnalysisConfig = { pbaHideFinancials: {} };
    pbaList.forEach((pba) => {
      pbasConfig.pbaHideFinancials[pba.pbaId] = previousReportPbaConfig[pba.pbaId] ?? false;
    });
    dispatch(actions.setProfitBasedAnalysisConfig(pbasConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Component
      selectedSection={selectedSection}
      jurisdictionName={jurisdictionName}
      upe={upe!}
      emitSectionSelected={emitSectionSelected}
      selectedTransactions={selectedTransactions}
      emitCurrentAppendixInAppendixConfigState={dispatchCurrentAppendixInAppendixConfigState}
      emitAddAppendixInAppendixConfigState={dispatchAddNewAppendixInAppendixConfigState}
      primaryEntities={selectedPrimaryEntities}
      tradingPartners={selectedTradingPartners}
      appendixConfigState={selectedAppendixConfigState}
      entityStatementFactsConfigState={selectedLegalEntityConfig}
      controlledTransactionsConfigState={selectedControlledTransactionsDocumentationConfig}
      customizedLegalEntities={customizedLegalEntities}
      customizedControlledTransactions={customizedControlledTransactions}
      customizedReportSections={customizedReportSections}
      selectedPBAs={pbaList}
      profitBasedAnalysisConfigState={selectedProfitBasedAnalysisConfig}
    />
  );
};

export default Connector;
